<template>
  <v-app id="inspire">
    <v-container>
      <v-row style="margin-top: -40px" v-if="printingMode">
        <v-col cols="10">
          <h5>{{ companyInfo.cnpj }}</h5>
          <h5>{{ companyInfo.name }}</h5>
          <h4>
            FOLHA DE PONTO - de {{ new Date(this.startDate).getDate() + 1 }}/{{
        new Date(this.startDate).getMonth() + 1
      }}
            até {{ new Date(this.endDate).getDate() + 1 }}/{{
        new Date(this.endDate).getMonth() + 1
      }}
            de {{ new Date(this.endDate).getFullYear() }}
          </h4>
          <hr />
          <p>
            FUNCIONÁRIO(A): {{ this.selectedEmployee.name.toUpperCase() }}
            <br />
            CPF: {{ this.selectedEmployee.document }} | PIS:
            {{ this.selectedEmployee.pis }}
          </p>
        </v-col>
        <v-col cols="2" class="text-right">
          <img class="logo" width="100px" src="@/assets/icon_dashbord.svg" />
        </v-col>
      </v-row>
      <v-row class="mt-1" v-if="!printingMode">
        <v-col cols="12" class="text-right">
          <h4 style="color: #757575">
            FOLHA DE PONTO
            <v-icon style="margin-top: -6px" large>mdi-table-clock</v-icon>
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row v-if="!printingMode">
        <v-col :cols="!printingMode ? 6 : 7" v-if="showIfRole(['Administrator', 'Owner', 'Supervisor'])">
          <div>
            <v-select :items="employees" label="Colaborador" v-model="selectedEmployeeId" item-value="id"
              @change="getPunchRecords()" item-text="name" solo></v-select>
          </div>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="startDate" type="date" label="Data Inicial" prepend-icon="mdi-calendar"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="endDate" type="date" label="Data Final" prepend-icon="mdi-calendar"></v-text-field>
        </v-col>
        <v-col cols="2" v-show="!printingMode" class="text-center">
          <v-btn class="mt-1" @click="getPunchRecords()" fab dark small color="primary">
            <v-icon dark> mdi-magnify </v-icon>
          </v-btn>
          <v-btn class="mt-1 ml-2" @click="downloadPunchSheet()" fab dark small color="danger">
            <v-icon dark> mdi-file-pdf-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <table border="1">
        <thead>
          <th>Dia</th>
          <th>Registros</th>
          <th>Horas trabalhadas</th>
        </thead>
        <tbody>
          <tr v-for="(punchGroup, index) in punchRecords" v-bind:key="index">
            <td>
              {{ formatDate(punchGroup.dateReference) }}
            </td>
            <td>
              <span v-for="punchDate in punchGroup.punchDates" v-bind:key="punchDate">
                <v-icon v-if="punchDate.punchType == 0"> mdi-arrow-up </v-icon>
                <v-icon v-else> mdi-arrow-down </v-icon>
                {{ formatTime(punchDate.punchDateTime) }}
              </span>
            </td>
            <td>
              {{ punchGroup.hoursWorked }}
            </td>
          </tr>
          <tr>
            <td>Total de Horas Trabalhadas:</td>
            <td colspan="5">
              {{ `${hoursTotal}` }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-container>
    <v-row class="mt-1">
      <v-col cols="6" class="text-center">
        _____________________________________________ <br />
        Assinatura do(a) Funcinonário(a)
      </v-col>
      <v-col cols="6" class="text-center">
        _____________________________________________ <br />
        Assinatura Empresa
      </v-col>
    </v-row>
  </v-app>
</template>
<style>
@media print {

  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>

<style scoped>
table {
  font-size: 12px;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 2px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>

<script>
import dayjs from 'dayjs'
export default {
  components: [dayjs],
  data() {
    return {
      hoursTotal: null,
      companyInfo: null,
      printingMode: false,
      selectedEmployee: null,
      selectedEmployeeId: null,
      startDate: new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            0
          ).getDate() == 31
            ? 0
            : 1
        )
      )
        .toISOString()
        .split("T")[0],
      endDate: new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            0
          ).getDate() == 31
            ? 29
            : 30
        )
      )
        .toISOString()
        .split("T")[0],
      employees: [],
      punchRecords: [],
    };
  },
  methods: {
    formatTime(timeUnformatted) {
      return dayjs(timeUnformatted).format("HH:mm:ss");
    },
    formatDate(dateUnformatted) {
      return dayjs(dateUnformatted).format("DD/MM/YYYY");
    },
    getCompanyInfo: function () {
      this.$axios
        .get(`/Reports/GetCompanyInfo`)
        .then((response) => {
          this.companyInfo = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar as informações. se persistir procure o suporte.",
          });
        });
    },
    downloadPunchSheet: function () {

      this.$axios
        .get(
          `/Reports/GetPunchSheetPDF?${this.selectedEmployeeId != null
            ? `employeeId=${this.selectedEmployeeId}`
            : ""
          }&startDate=${this.startDate}&endDate=${this.endDate}`,
          { responseType: 'blob' }
        )
        .then((response) => {
          console.log(response);

          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'punch_sheet.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();



        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar os pontos. se persistir procure o suporte.",
          });
        });

      // this.togglePrintingMode();
      // setTimeout(() => {
      //   window.print();
      //   this.togglePrintingMode();
      // }, 300);
    },
    togglePrintingMode: function () {
      this.printingMode = !this.printingMode;
      this.$emit("toggleMenu");
    },
    getPunchRecords: function () {
      this.$axios
        .get(
          `/Reports/GetPunchSheet?${this.selectedEmployeeId != null
            ? `employeeId=${this.selectedEmployeeId}`
            : ""
          }&startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((response) => {
          console.log(response);
          for (let index = 0; index < this.employees.length; index++) {
            const element = this.employees[index];
            if (element.id == this.selectedEmployeeId) {
              this.selectedEmployee = element;
            }
          }

          this.punchRecords = response.data.punchRecords;
          this.hoursTotal = response.data.totalHoursWorked;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar os pontos. se persistir procure o suporte.",
          });
        });
    },
    getEmployees: function () {
      this.$axios
        .get(`/employee`)
        .then((response) => {
          this.employees = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar os colaboradores. se persistir procure o suporte.",
          });
        });
    },
    getLoggedEmployee: function () {
      this.$axios
        .get(`reports/GetCurrentlyLoggedEmployee`)
        .then((response) => {
          this.selectedEmployee = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar os colaboradores. se persistir procure o suporte.",
          });
        });
    },
    showIfRole: function (roles) {
      return roles.includes(this.$cookies.get("point_sessionInfo").role);
    },
  },
  mounted() {
    this.getCompanyInfo();
    if (!this.showIfRole("StandardEmployee")) {
      this.getEmployees();
    } else {
      this.getLoggedEmployee();
    }
  },
};
</script>