<template>
  <v-expansion-panels :value="0">
    <v-expansion-panel>
      <v-expansion-panel-header color="#383266" class="text-white">
        <span>
          <v-icon
            style="
              margin-top: -6px;
              margin-right: 2px;
              margin-left: -6px;
              color: white;
            "
            >mdi-clock-outline</v-icon
          >
          Últimos eventos
        </span>
        <template v-slot:actions>
          <v-icon color="white"> $expand </v-icon>
        </template></v-expansion-panel-header
      >
      <v-expansion-panel-content two-line>
        <loading :active="showLoadingComponent" :is-full-page="false" />
        <div v-if="events.length == 0" class="text-center pa-5">
          <v-icon large>mdi-robot-happy</v-icon>
          <br />
          <span style="font-size: 22px; font-weight: 350"
            >Por enquanto, não há eventos a exibir.</span
          >
        </div>
        <v-list v-else>
          <template v-for="(event, index) in events">
            <v-list-item :key="index">
              <v-list-item-avatar>
                <img
                  v-if="event.eventType == 'PunchIn'"
                  src="@/assets/clock-in.png"
                />
                <img
                  v-else-if="event.eventType == 'PunchOut'"
                  src="@/assets/clock-out.png"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="event.eventType == 'PunchIn'"
                  >{{ event.employeeName }} começou a
                  trabalhar.</v-list-item-title
                >
                <v-list-item-title v-else-if="event.eventType == 'PunchOut'"
                  >{{ event.employeeName }} interrompeu o
                  trabalho.</v-list-item-title
                >
                <v-list-item-subtitle>
                  {{
                    dayjs(event.eventDateTime).format(
                      "DD/MM/YYYY [às] HH:mm:ss"
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<style scoped>
.text-white {
  color: #fff !important;
}
</style>
<style>
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import * as dayjs from "dayjs";
export default {
  name: "LastEvents",
  data: () => ({
    showLoadingComponent: false,
    dayjs,
    isInError: false,
    events: { length: 0 },
  }),
  components: {
    Loading,
  },
  methods: {
    getEvents: function () {
      this.showLoadingComponent = true;
      this.$axios
        .get(`Stats/GetLast5Events`)
        .then((response) => {
          this.events = response.data;
        })
        .catch((error) => {
          this.isInError = true;
          console.log(error);
        })
        .finally(() => {
           this.showLoadingComponent =false;
        });
    },
  },
  mounted() {
    this.getEvents();
  },
};
</script>
