<template>
  <v-app id="inspire">
    <v-container>
      <v-row cols="12">
        <v-col cols="12" v-if="showIfRole(['Administrator', 'Owner', 'StandardEmployee', 'Supervisor'])">
          <clock-bar />
        </v-col>
        <v-col cols="12" v-if="showIfRole(['Administrator', 'Owner'])">
          <administrative-bar />
        </v-col>
        <v-col md="6" v-if="showIfRole(['Administrator', 'Owner', 'StandardEmployee', 'Supervisor'])">
          <last-events/>
        </v-col>
        <v-col md="6" v-if="showIfRole(['Administrator', 'Owner', 'StandardEmployee', 'Supervisor'])">
          <OccurencePanel/>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import ClockBar from "@/components/Home/ClockBar.vue";
import AdministrativeBar from "@/components/Home/AdministrativeBar.vue";
import LastEvents from "@/components/Home/EventsBar.vue";
import OccurencePanel from '@/components/Home/OccurrencePanel.vue';

export default {
  components: { AdministrativeBar, LastEvents, OccurencePanel,ClockBar },
  name: "App",

  data: () => ({
    breadcrumbItems: [
      {
        text: "Início",
        disabled: false,
        href: "/",
      },
    ],
  }),
  methods: {
    showIfRole: function (roles) {
      return roles.includes(this.$cookies.get("point_sessionInfo").role);
    },
  },
  created() {},
};
</script>
