<template>
  <v-app id="inspire" v-if="showIfRole(['Administrator', 'Owner'])">
    <v-container>
      <v-row class="mt-1">
        <v-col cols="12" class="text-right">
          <h4 style="color: #757575">
            COLABORADORES
            <v-icon style="margin-top: -6px" large>mdi-account-group</v-icon>
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row cols="12">
        <v-col cols="12">
          <div>
            <v-text-field
              v-model="search"
              label="Pesquisar"
              solo
              class="mx-4"
            ></v-text-field>
            <v-data-table
              locale="pt"
              :headers="headers"
              :items="listData"
              item-key="name"
              class="elevation-1"
              :search="search"
            >
              <template
                v-for="header in headers.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>

              <template v-slot:[`item.role`]="{ item }">
                <span>{{ rolesDict[item.role] }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <a :href="`employees/edit/${item.id}`">
                  <v-icon small class="mr-2"> mdi-pencil </v-icon>
                </a>
                <v-icon small @click="deleteEmployee(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      class="point-fab"
      to="employees/add"
      large
      color="primary"
      elevation="2"
      fab
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </v-app>
</template>
<style scoped>
a {
  text-decoration: none;
}
</style>

<script>
export default {
  data() {
    return {
      rolesDict: {
        0: "Propietário",
        1: "Administrador",
        2: "Supervisor",
        3: "Colaborador",
      },
      birthDate: "",
      search: "",
      listData: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Colaborador", value: "name" },
        { text: "CPF", value: "document" },
        {
          text: "Data de Nascimento",
          value: "birthDate",
          formatter: this.formatDate,
        },
        { text: "E-mail", value: "email" },
        { text: "Papel", value: "role" },
        {
          text: "Ações",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    formatDate: function (date) {
      return new Date(date).toLocaleDateString('pt-BR', {timeZone: 'UTC'}).split(",")[0];
    },
    deleteEmployee: function (employee) {
      this.$swal
        .fire({
          title: `Confrime a ação`,
          text: `Você estará excluindo a conta do colaborador: ${employee.name}.`,
          showCancelButton: true,
          confirmButtonText: "Confirmar exclusão",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/employee?employeeId=${employee.id}`)
              .then(() => {
                this.getEmployees();
                this.$swal.fire("Colaborador excluído.", "", "success");
              })
              .catch((error) => {
                console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Tente novamente mais tarde",
                  text: "O sistema não conseguiu excluir o colaboradore. se persistir procure o suporte.",
                });
              });
          }
        });
    },
    getEmployees: function () {
      this.$axios
        .get(`/employee`)
        .then((response) => {
          this.listData = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar os colaboradores. se persistir procure o suporte.",
          });
        });
    },
    showIfRole: function (roles) {
      return roles.includes(this.$cookies.get("point_sessionInfo").role);
    },
  },
  mounted() {
    this.getEmployees();
  },
};
</script>