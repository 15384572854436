import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueMask from 'v-mask';

import pt from 'vuetify/lib/locale/pt'

Vue.use(VueMask);
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
        themes: {
            light: {
                primary: '#171060',
                secondary: '#b0bec5',
                accent: '#454343',
                error: '#b71c1c',
            },
        },
    },
});