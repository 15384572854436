<template>
  <v-app id="inspire" v-if="showIfRole(['Administrator', 'Owner'])">
    <v-container>
      <v-row class="mt-1">
        <v-col cols="12" class="text-right">
          <h4 style="color: #757575">
            ADICIONANDO COLABORADOR
            <v-icon style="margin-top: -6px" large>mdi-account-group</v-icon>
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row cols="12">
        <v-col cols="12">
          <div>
            <form>
              <v-text-field
                v-model="employee.name"
                label="Nome"
                required
              ></v-text-field>
              <v-text-field
                type="date"
                v-model="employee.birthDate"
                label="Data de nascimento"
                required
              ></v-text-field>
              <v-text-field
                v-model="employee.email"
                label="E-mail"
                required
              ></v-text-field>
              <v-text-field
                v-model="employee.password"
                type="password"
                hint="Deixe o campo vazio se não quiser mudar a senha."
                persistent-hint
                label="Senha"
              ></v-text-field>
              <v-text-field
                class="mt-5"
                v-model="employee.document"
                label="CPF"
                v-mask="'###.###.###-##'"
                required
              ></v-text-field>
              <v-text-field v-model="employee.pis" label="PIS"></v-text-field>
              <v-text-field
                v-model="employee.phone"
                label="Telefone"
                required
              ></v-text-field>
              <v-select
                v-model="employee.role"
                :items="roles"
                item-text="role"
                item-value="id"
                label="Permissão / Papel"
                required
              ></v-select>

              <v-btn class="mr-4" color="primary" @click="addEmployee()">
                Salvar
              </v-btn>
              <v-btn to="/employees" href="#"> Cancelar </v-btn>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn class="point-fab" large color="primary" elevation="2" fab
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      employee: {
        birthDate: "",
        name: "",
        email: "",
        password: "",
        document: "",
        role: "",
        pis: "",
      },
      roles: [
        { id: 0, role: "Proprietário" },
        { id: 1, role: "Administrador" },
        { id: 2, role: "Supervisor" },
        { id: 3, role: "Colaborador" },
      ],
    };
  },
  methods: {
    getEmployee: function (id) {
      this.$axios
        .get(`/employee?employeeId=${id}`)
        .then((response) => {
          this.employee = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar o colaborador. se persistir procure o suporte.",
          });
        });
    },
    addEmployee: function () {
      console.log(this.employee);
      this.$axios
        .post(`/employee`, this.employee)
        .then(() => {
          this.$swal
            .fire("O colaborador foi adicionado.", "", "success")
            .then(() => {
              window.location.href = "/employees";
            });
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu atualizar os dados do colaborador. se persistir procure o suporte.",
          });
        });
    },
    showIfRole: function (roles) {
      return roles.includes(this.$cookies.get("point_sessionInfo").role);
    },
  },
  mounted() {},
};
</script>