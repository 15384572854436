const navigation = [{
        icon: "mdi-home",
        text: "Início",
        href: "/",
        roles: ["Administrator", "Owner", "Supervisor", "StandardEmployee"]
    },
    {
        icon: "mdi-account-group",
        text: "Colaboradores",
        href: "/employees",
        roles: ["Administrator", "Owner"]
    },
    {
        icon: "mdi-progress-pencil",
        text: "Ocorrências",
        href: "/occurrences",
        roles: ["Owner", "Administrator", "Supervisor", "StandardEmployee"]
    },
    {
        icon: "mdi-table-clock",
        text: "Folha de Ponto",
        href: "/punch-sheet",
        hideMobile: true,
        roles: ["Owner", "Administrator", "Supervisor", "StandardEmployee"]
    }
];

export default navigation