<template>
  <v-app id="inspire" v-if="showIfRole(['Administrator', 'Owner', 'StandardEmployee', 'Supervisor'])">
    <v-container>
      <v-row class="mt-1">
        <v-col cols="12" class="text-right">
          <h4 style="color: #757575">
            OCORRÊNCIAS
            <v-icon style="margin-top: -6px" large>mdi-progress-pencil</v-icon>
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row cols="12">
        <v-col cols="12">
          <div>
            <v-text-field v-model="search" label="Pesquisar" solo class="mx-4"></v-text-field>
            
            <v-btn-toggle v-model="curruentFilterStatus" @change="getOccurrences()" class="mx-4">
              <v-btn text :value="null">Pendentes ({{ amountByStatus.pending }})</v-btn>
              <v-btn text :value="true">Aprovadas ({{ amountByStatus.approved }})</v-btn>
              <v-btn text :value="false">Rejeitadas ({{ amountByStatus.rejected }})</v-btn>
            </v-btn-toggle>
            <v-data-table locale="pt" :headers="headers" :items="listData" item-key="name" class="mt-5 elevation-1"
              :search="search">
              <template v-for="header in headers.filter((header) =>
    header.hasOwnProperty('formatter')
  )" v-slot:[`item.${header.value}`]="{ value }">
                {{ header.formatter(value) }}
              </template>

              <template v-slot:[`item.role`]="{ item }">
                <span>{{ rolesDict[item.role] }}</span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <a :href="`occurrences/view/${item.id}`">
                  <v-icon small class="mr-2"> mdi-eye </v-icon>
                </a>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <v-btn class="point-fab" to="occurrences/add" large color="primary" elevation="2"
      fab><v-icon>mdi-plus</v-icon></v-btn>
  </v-app>
</template>
<style scoped>
a {
  text-decoration: none;
}
</style>

<script>
export default {
  data() {
    return {
      curruentFilterStatus: null,
      amountByStatus: null,
      search: "",
      listData: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Colaborador", value: "employeeName" },
        { text: "Data da Ocorrência", value: "occurrenceDate", formatter: this.formatDate },
        { text: "Descrição", value: "occurrenceDescription" },
        { text: "Status", value: "isApproved", formatter: this.formatStatus },
        {
          text: "Ações",
          value: "actions",
        },
      ];
    },
  },
  methods: {
    formatDate: function (date) {
      return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).split(",")[0];
    },
    formatStatus: function (status) {
      if (status == null) return 'Pendente';
      return status ? "Aprovada" : "Rejeitada";
    },
    filterByCurrentStatus: function () {
      console.log(this.curruentFilterStatus);
      this.listData = this.listData.filter((item) => item.isApproved == this.curruentFilterStatus);
      console.log(this.listData);
    },
    getOccurrences: function () {
      this.$axios
        .get(`/occurrence`)
        .then((response) => {
          this.listData = response.data;
          this.amountByStatus = this.listData.reduce((acc, occurrence) => {
            if (occurrence.isApproved == null) {
              acc.pending++;
            } else if (occurrence.isApproved) {
              acc.approved++;
            } else {
              acc.rejected++;
            }
            return acc;
          }, { pending: 0, approved: 0, rejected: 0 });


          this.filterByCurrentStatus();

          this.listData.sort((a, b) => {
            return new Date(b.occurrenceDate) - new Date(a.occurrenceDate);
          });


        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar as ocorrências. se persistir procure o suporte.",
          });
        });
    },
    showIfRole: function (roles) {
      return roles.includes(this.$cookies.get("point_sessionInfo").role);
    },
  },
  mounted() {
    this.getOccurrences();
  },
};
</script>