<template>
  <v-expansion-panels :value="0">
    <v-expansion-panel>
      <v-expansion-panel-header color="#383266" class="text-white">
        <span>
          <v-icon style="
              margin-top: -6px;
              margin-right: 2px;
              margin-left: -6px;
              color: white;
            ">mdi-file-alert-outline</v-icon>
          Ocorrências pendentes
        </span>
        <template v-slot:actions>
          <v-icon color="white"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content two-line>
        <loading :active="showLoadingComponent" :is-full-page="false" />
        <div v-if="occurrences.length == 0" class="text-center pa-5">
          <v-icon large>mdi-robot-happy</v-icon>
          <br />
          <span style="font-size: 22px; font-weight: 350">Não há ocorrências pendentes.</span>
        </div>
        <v-list-item-group v-else>
          <a v-for="(occurrence, index) in occurrences" v-bind:key="index" :href="`occurrences/view/${occurrence.id}`" style="text-decoration: none;">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><strong>#{{ occurrence.id }}, de {{ occurrence.employeeName }}</strong>, sobre o
                  {{
                    dayjs(occurrence.occurrenceDate).format("DD/MM/YYYY")
                  }} <br />
                  <small>{{occurrence.occurrenceDescription}}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </v-list-item-group>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<style scoped>
.text-white {
  color: #fff !important;
}
</style>
<style>
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
<script>
import Loading from "vue-loading-overlay";
import * as dayjs from "dayjs";
export default {
  name: "OccurrencePanel",

  data: () => ({
    showLoadingComponent: false,
    dayjs,
    occurrences: { length: 0 },
    isInError: false,
  }),
  components: {
    Loading,
  },
  methods: {
    formatStatus: function (status) {
      if (status == null) return 'Pendente';
      return status ? "Aprovada" : "Rejeitada";
    },
    getOccurrences: function () {
      this.showLoadingComponent = true;
      this.$axios
        .get(`Stats/GetLast5PendingOccurrences`)
        .then((response) => {
          this.occurrences = response.data;
        })
        .catch((error) => {
          this.isInError = true;
          console.log(error);
        })
        .finally(() => {
          this.showLoadingComponent = false;
        });
    },
  },
  mounted() {
    this.getOccurrences();
  },
};
</script>