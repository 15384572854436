<template>
  <div class="home">

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="login text-white">
        <div class="px-1">
          <v-row>
            <v-col cols="4">
              <img src="../assets/icon_1_negative.svg" />
            </v-col>
            <v-col cols="8">
              <br />
              <h5>Point - Controle de Ponto Digital</h5>
              <h2>Bem-vindo colaborador(a)</h2>
              <p>
                Informe sua matrícula abaixo e selecione a opção de "Registrar ponto".
              </p>

            </v-col>
          </v-row>
        </div>
        <br />
        <v-text-field filled rounded dense dark height="55" label="Matrícula" hide-details="auto"
          v-model="matricula"></v-text-field>
        <br />       
        <v-btn class="point-fab" large color="primary" elevation="2"
      fab><v-icon>mdi-arrow-right</v-icon></v-btn> 
     

      </v-col>
    </v-row>
    <v-row class="curentTimeAndDate">
      <div class="px-2 text-center">
        <h2 class="text-white text-center">
          <v-icon dark>mdi-clock-time-four</v-icon>
          {{ currentDateTime }}
        </h2>
      </div>
    </v-row>
  </div>
</template>

<style scoped>

.point-fab {
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 15%;
  z-index: 999;
}

@media (min-width: 720px) {
  .logo {
    width: 400px;
    left: 30px;
    top: 40%;
    position: fixed;
  }
}

@media (max-width: 719px) {
  .logo {
    width: 75%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.text-white {
  color: #fff;
}

.login {
  background: #171060c9;
  height: 100vh;
  padding: 10px !important;
}

.v-text-field {
  font-size: 22px;
}

.curentTimeAndDate {
  width: 110%;
  height: 50px;
  padding-left: 10px;
  background: #383266;
  position: fixed;
  bottom: 0;
}
</style>
<script>
export default {
  name: "LoginView",
  data: function () {
    return {
      matricula: "",
      currentDateTime: "",
    };
  },
  components: {},
  methods: {
    quickPunch: function () {
      this.$axios
        .post(`/session/quick`, {
          employeeId: this.matricula,
        })
        .then(() => {
          this.$swal.fire("Quase lá.", "Confirme a batida de ponto através do link que você recebeu em seu e-mail.", "info");
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Verifique seus dados",
            text: "Matrícula não encontrada ou inválida.",
          });
          console.log(error);
        });
    },
  },
  created() {
    setInterval(() => {
      this.currentDateTime = new Date().toLocaleString("pt-br");
    }, 1000);
  },
};
</script>
