import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies'
import axios from "axios";
import VueHorizontalTimeline from "vue-horizontal-timeline";


Vue.use(VueHorizontalTimeline);
Vue.use(VueCookies);
Vue.use(axios);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false

import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#454343',
    cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);


new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')