<template>
  <div class="home">
    <v-row class="curentTimeAndDate">
      <div class="px-2 text-center">
        <h2 class="text-white text-center">
          <v-icon dark>mdi-clock-time-four</v-icon>
          {{ currentDateTime }}
        </h2>
      </div>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-center" cols="12" sm="12" md="6">
        <img class="logo" src="../assets/logo_2.svg" />
      </v-col>
      <v-col cols="12" sm="12" md="6" class="login text-white">
        <div class="px-2">
          <h2>Bem-vindo colaborador(a)</h2>
          <p>
            Informe seus dados abaixo e selecione a opção de "Registrar ponto"
            ou "Entrar'.
          </p>
        </div>
        <v-text-field filled rounded dense dark height="55" label="CPF" hide-details="auto" v-model="cpf"
          v-mask="'###.###.###-##'"></v-text-field>
        <br />
        <v-text-field filled rounded dense height="55" dark type="password" v-model="password"
          v-on:keyup.enter="login()" label="Senha" hide-details="auto"></v-text-field>
        <br />
        <v-btn block color="#D3863E" class="text-white" elevation="2" @click="punchClock()" large rounded>Registrar
          ponto</v-btn>
        <v-btn block style="margin-top: 10px" color="accent" class="text-white" v-on:click="login()" elevation="2" large
          rounded>Entrar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
@media (min-width: 720px) {
  .logo {
    width: 400px;
    left: 30px;
    top: 40%;
    position: fixed;
  }
}

@media (max-width: 719px) {
  .logo {
    width: 75%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.text-white {
  color: #fff;
}

.login {
  background: #171060c9;
  height: 100vh;
  padding: 40px !important;
  padding-top: 15% !important;
}

.v-text-field {
  font-size: 22px;
}

.curentTimeAndDate {
  width: 110%;
  height: 50px;
  padding-left: 10px;
  background: #383266;
  position: fixed;
  bottom: 0;
}
</style>
<script>
export default {
  name: "LoginView",
  data: function () {
    return {
      cpf: "",
      password: "",
      currentDateTime: "",
    };
  },
  components: {},
  methods: {
    punchClock: function () {

      navigator.permissions && navigator.permissions.query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state !== 'granted') {
            this.$swal
              .fire({
                title: `Por favor, permita acesso a geolocalização para bater o ponto.`,
                type: "error"
              })
            return;
          }

          this.$axios
            .post(`/session/start`, {
              document: this.cpf,
              password: this.password,
            })
            .then((response) => {
              this.$swal
                .fire("Aguarde", "Autenticado, em seguida registrando seu ponto.", "info");

              this.$cookies.set("point_sessionInfo", response.data);

              let el = this;

              el.$axios.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.sessionToken;
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  function (position) {
                    el.$axios
                      .post(`/punch`, { Latitude: `${position.coords.latitude}`, Longitude: `${position.coords.longitude}` })
                      .then(() => {
                        el.$swal
                          .fire("O ponto foi batido.", "", "success")
                          .then(() => {
                            location.reload();
                          });
                      })
                      .catch((error) => {
                        el.$swal({
                          icon: "error",
                          title: "Tente novamente",
                          text: "Um erro aconteceu, por gentileza tente novamente. Se persistir, procure ajuda.",
                        });
                        console.log(error);
                      });
                  },
                  function (error) {
                    console.error('Error getting location: ' + error.message);
                    el.$swal
                      .fire({
                        title: `Não é possível bater o ponto sem permitir acesso à localização.`,
                        icon: "error"
                      })
                  }
                );
              } else {
                el.$swal
                  .fire({
                    title: `Não é possível bater o ponto sem permitir acesso à localização.`,
                    type: "error"
                  })
              }

            })
            .catch((error) => {
              this.$swal({
                icon: "error",
                title: "Verifique seus dados",
                text: "Usuário e/ou Senha incorreto(s)",
              });
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
          if (!navigator.geolocation) {
            this.$swal
              .fire({
                title: `Por favor, permita acesso a geolocalização para bater o ponto.`,
                type: "error"
              })
          }
        });





    },
    login: function () {
      this.$axios
        .post(`/session/start`, {
          document: this.cpf,
          password: this.password,
        })
        .then((response) => {
          this.$cookies.set("point_sessionInfo", response.data);
          window.location.replace("/");
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Verifique seus dados",
            text: "Usuário e/ou Senha incorreto(s)",
          });
          console.log(error);
        });
    },
  },
  created() {
    setInterval(() => {
      this.currentDateTime = new Date().toLocaleString("pt-br");
    }, 1000);
  },
};
</script>
