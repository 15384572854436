<template>
  <v-row class="text-center">
    <v-col cols="4">
      <v-card class="text-white pa-5" color="#10ac84" elevation="2">
        <h3>{{ stats.amountInJourney || "..." }}</h3>
        <span>Em jornada</span>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="text-white pa-5" color="#ee5253" elevation="2">
        <h3>{{ stats.amountOffJourney || "..." }}</h3>
        <span>Fora de jornada</span>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="text-white pa-5" color="#222f3e" elevation="2">
        <h3>{{ stats.employeesAmount || "..." }}</h3>
        <span>Colaboradores</span>
      </v-card>
    </v-col>
  </v-row>
</template>
<style scoped>
.text-white {
  color: #fff !important;
}
</style>
<script>
export default {
  name: "AdministrativeBar",

  data: () => ({
    stats: {
      amountInJourney: null,
      amountOffJourney: null,
      employeesAmount: 1,
    },
    isInError: false,
  }),
  methods: {
    getStats: function () {
      this.$axios
        .get(`Stats/GetAdministrativeBarStats`)
        .then((response) => {
          this.stats = response.data;
        })
        .catch((error) => {
          this.isInError = true;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getStats();
  },
};
</script>
