<template>
  <v-app id="inspire" v-if="showIfRole(['Administrator', 'Owner', 'StandardEmployee', 'Supervisor'])">
    <v-container>
      <v-row class="mt-1">
        <v-col cols="12" class="text-right">
          <h4 style="color: #757575">
            ADICIONANDO OCORRÊNCIA
            <v-icon style="margin-top: -6px" large>mdi-progress-pencil</v-icon>
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row cols="12">
        <v-col cols="12">
          <div>
            <form>
              <v-text-field type="date" v-model="occurrence.occurrenceDate" label="Data da Ocorrência"
                @change="getPunchsFromDate(occurrence.occurrenceDate)" required></v-text-field>
              <v-textarea label="Descreva a ocorrência" v-model="occurrence.occurrenceDescription" required></v-textarea>
              <v-text-field v-for="(punch, index) in occurrence.punches" v-bind:key="index" type="time"
                :label="'Horário de ' + (((index + 1) % 2 == 0) ? 'Saída' : 'Entrada')" required
                v-model="occurrence.punches[index]">
              </v-text-field>
              <v-btn v-if="occurrence.punches.length > 0" @click="addRange(2)" small> Adicionar horários </v-btn>
              <v-btn v-if="occurrence.punches.length > 0" @click="removeRange(2)" color="#34495e" class="white--text"
                small> Remover horários (2 últimos) </v-btn>

              <br />
              <br />
              <v-btn class="mr-4" color="primary" @click="addOccurrence()">
                Salvar
              </v-btn>
              <v-btn to="/occurrences" href="#"> Cancelar </v-btn>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn class="point-fab" large color="primary" elevation="2" fab><v-icon>mdi-plus</v-icon></v-btn>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      occurrence: {
        occurrenceDate: "",
        occurrenceDescription: "",
        proposedCorrectionJson: "",
        punches: []
      }
    };
  },
  methods: {
    addRange: function (amount) {
      for (let index = 0; index < amount; index++) {
        this.occurrence.punches.push('');
      }
    },
    removeRange: function (amount) {
      this.occurrence.punches = this.occurrence.punches.slice(0, this.occurrence.punches.length - amount);
    },
    getPunchsFromDate: function (date) {
      this.$axios
        .get(`/occurrences/getPunchesFromDate?punchDate=${date}`)
        .then((response) => {
          if (response.data) {
            this.occurrence.punches = response.data;
          }
          var restingFields = 4 - this.occurrence.punches.length;
          for (let index = 0; index < restingFields; index++) {
            this.occurrence.punches.push('');
          }

        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu recuperar batidas para o dia selecionado. se persistir procure o suporte.",
          });
        });
    },
    addOccurrence: function () {
      
      this.occurrence.proposedCorrectionJson = JSON.stringify(this.occurrence.punches);

      console.log(this.occurrence);

      this.$axios
        .post(`/occurrence`, this.occurrence)
        .then(() => {
          this.$swal
            .fire("A ocorrência foi aberta e enviada para o(a) responsável.", "", "success")
            .then(() => {
              window.location.href = "/occurrences";
            });
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Tente novamente mais tarde",
            text: "O sistema não conseguiu adicionar a ocorrência. se persistir procure o suporte.",
          });
        });
    },
    showIfRole: function (roles) {
      return roles.includes(this.$cookies.get("point_sessionInfo").role);
    },
  },
  mounted() { },
};
</script>