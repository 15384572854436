<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-card class="pa-5" color="#ffffff" elevation="2">
        <small>Hora Legal Brasileira</small>
        <h3 v-if="currentDateTime">{{ currentDateTime.toLocaleString("pt-br").split(" ")[1] }}</h3>
        <br />
        <v-divider></v-divider>
        <br />
        <v-btn block color="#D3863E" class="text-white" elevation="2" @click="punchClock()" large rounded>Registrar
          ponto</v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>
<style scoped>
.text-white {
  color: #fff !important;
}
</style>
<script>
export default {
  name: "ClockBar",

  data: () => ({
    stats: null,
    isInError: false,
    currentDateTime: null,
  }),
  methods: {
    punchClock: function () {



      const el = this;
      this.$swal
        .fire({
          title: `Confirme o registro de ponto`,
          showCancelButton: true,
          confirmButtonText: "Confirmar registro",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {

            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                function (position) {
                  el.$axios
                    .post(`/punch`, { Latitude: `${position.coords.latitude}`, Longitude: `${position.coords.longitude}` })
                    .then(() => {
                      el.$swal
                        .fire("O ponto foi batido.", "", "success")
                        .then(() => {
                          location.reload();
                        });
                    })
                    .catch((error) => {
                      el.$swal({
                        icon: "error",
                        title: "Tente novamente",
                        text: "Um erro aconteceu, por gentileza tente novamente. Se persistir, procure ajuda.",
                      });
                      console.log(error);
                    });
                },
                function (error) {
                  console.error('Error getting location: ' + error.message);
                  el.$swal
                    .fire({
                      title: `Não é possível bater o ponto sem permitir acesso à localização.`,
                      icon: "error"
                    })
                }
              );
            } else {
              el.$swal
                .fire({
                  title: `Não é possível bater o ponto sem permitir acesso à localização.`,
                  type: "error"
                })
            }


          }
        });
    },
    getStats: function () {
      this.$axios
        .get(`Stats/GetDateTime`)
        .then((response) => {
          this.stats = response.data;
        })
        .catch((error) => {
          this.isInError = true;
          console.log(error);
        });
    },
    addSeconds: function (numOfSeconds, date = new Date()) {
      return date.setSeconds(date.getSeconds() + numOfSeconds);
    },
  },
  mounted() {
    this.getStats();
    this.$axios
      .get(`Stats/GetDateTime`)
      .then((response) => {
        var startDate = response.data;
        this.currentDateTime = new Date(startDate);
        setInterval(() => {
          this.currentDateTime = new Date(
            this.addSeconds(1, new Date(this.currentDateTime))
          );
        }, 1000);
      })
      .catch((error) => {
        this.isInError = true;
        console.log(error);
      });
  },
};
</script>
