import Vue from 'vue'
import VueRouter from 'vue-router'
import QuickLoginView from '../views/QuickLoginView.vue'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/Home/HomeView.vue'

import EmployeesHome from '../views/Employees/EmployeesHome.vue'
import EmployeeEdit from '../views/Employees/EmployeeEdit.vue'
import EmployeeAdd from '../views/Employees/EmployeeAdd.vue'

import PunchSheetHome from '../views/PunchSheet/PunchSheetHome.vue'

import OccurrencesHome from '../views/Occurrences/OccurrencesHome.vue'
import OccurrencesAdd from '../views/Occurrences/OccurrenceAdd.vue'
import OccurrencesView from '../views/Occurrences/OccurrenceView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/quick',
        name: 'quick login',
        component: QuickLoginView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/employees',
        name: 'employees',
        component: EmployeesHome
    },
    {
        path: '/employees/edit/:id',
        name: 'edit employee',
        component: EmployeeEdit
    },
    {
        path: '/employees/add',
        name: 'add employee',
        component: EmployeeAdd
    },
    {
        path: '/punch-sheet',
        name: 'punch sheet',
        component: PunchSheetHome
    },
    {
        path: '/occurrences',
        name: 'occurrences',
        component: OccurrencesHome
    },
    {
        path: '/occurrences/add',
        name: 'add occurrences',
        component: OccurrencesAdd
    },
    {
        path: '/occurrences/view/:id',
        name: 'view occurrence',
        component: OccurrencesView
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router