<template>
  <v-app>
    <v-main>
      <loading :active="showLoading" :is-full-page="true" />
      <div v-if="!isLogin">
        <v-app-bar app clipped-left dark color="primary" class="d-print-none" style="height: 75px;">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-toolbar-title class="mobile-screen mt-5">Point

            <div style="font-size: 12px;">
              Colaborador: {{ sessionInfo.employeeName }}<br />
              Empresa: {{ sessionInfo.companyName }}<br />
            </div>


          </v-toolbar-title>
        </v-app-bar>
        <!-- <v-system-bar app color="primary" v-show="drawer">
          <v-spacer></v-spacer>
        </v-system-bar> -->

        <v-navigation-drawer v-model="drawer" class="d-print-none" :permanent="!$vuetify.breakpoint.xsOnly && drawer"
          app>
          <v-sheet color="#383266" class="pa-4 text-white">
            <v-avatar class="mb-4" size="64">
              <img class="logo" src="@/assets/icon_dashbord.svg" />
            </v-avatar>
            <div style="color: #fff">
              Olá, {{ sessionInfo.employeeName }}.
              <br/>
              <small>
                {{ sessionInfo.companyName }}
              </small>
            </div>
          </v-sheet>

          <v-divider></v-divider>

          <v-list>
            <v-list-item v-for="(link, index) in links" :key="index" link :to="link.href">
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>


        <v-container fluid>
          <router-view @toggleMenu="toggleMenu" />
        </v-container>
      </div>
      <div v-else>
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<style>
.swal2-actions,
.swal2-html-container,
.swal2-title {
  font-family: "Roboto";
}

.point-fab {
  right: 10px !important;
  bottom: 10px;
  position: fixed !important;
}

@media (max-width: 600px) {
  .mobile-screen {
    display: block;
  }

  .desktop-screen {
    display: none;
  }
}

@media (min-width: 601px) {
  .mobile-screen {
    display: none;
  }

  .desktop-screen {
    display: block;
  }
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import navigation from "@/navigation";
export default {
  name: "App",

  data: () => ({
    authToken: "",
    drawer: true,
    showLoading: false,
  }),
  computed: {
    isLogin() {
      return this.$route != null ? (this.$route.path.includes("login") || this.$route.path.includes("quick")) : false;
    },
    links() {
      var avaiableLinks = [];
      navigation.forEach((link) => {
        if (link.roles.includes(this.sessionInfo.role))
          avaiableLinks.push(link);
      });
      return avaiableLinks;
    },
    sessionInfo() {
      console.log(this.$cookies.get("point_sessionInfo"));
      return this.$cookies.get("point_sessionInfo");
    },
  },
  components: {
    Loading,
  },
  methods: {
    logout: function () {
      this.$cookies.remove("point_sessionInfo");
      window.location.replace("/login");
    },
    toggleMenu: function () {
      this.drawer = !this.drawer;
    },
  },
  mounted() {
    this.$on("toggleMenu", this.toggleMenu());
  },
  created() {
    if (!this.isLogin && this.sessionInfo == null) {
      window.location.replace("/login");
    }

    let self = this;
    var axios = { ...this.$axios };

    //get current address of open browser window
    var currentAddress = window.location.href;
    if (currentAddress.includes("hml")) {
      axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL_HML;
    }
    else if (currentAddress.includes("localhost")) {
      axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL_LOCAL;
    }
    else {
      axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL_PROD;
    }

    if (this.sessionInfo != null) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.sessionInfo.sessionToken;
    }

    this.$axios = axios;

    this.$axios.interceptors.request.use(
      function (config) {
        self.showLoading = true;
        return config;
      },
      function (error) {
        self.showLoading = false;
        return Promise.reject(error);
      }
    );

    this.$axios.interceptors.response.use(
      function (response) {
        self.showLoading = false;
        return response;
      },
      function (error) {
        self.showLoading = false;
        return Promise.reject(error);
      }
    );
  },
};
</script>
